//import $ from 'jquery';
//import anime from 'animejs';
//import modernizr from 'gulp-modernizr';

//var modernizr = require("modernizr");





/**
 * Header flip
 * 
 * Ãœberschrift mit animierten Texten
 */
$(document).ready(function () {
   const timeout = 1500;

   function animateFliptext($hf, $items) {
      $items.each(function (index) {
         let $item = $(this);
         setTimeout(function () {
            $item.addClass("active animated slideInDown");
            if ($hf.hasClass("text-center")) {
               const activeWidth = $item.width();
               $hf.css('margin-left', -activeWidth);
            }
            setTimeout(function () {
               $item.removeClass("active slideInDown").addClass("slideOutDown");
               setTimeout(function () {
                  $item.removeClass("animated slideOutDown")
               }, timeout);
            }, timeout);
         }, timeout * index);
      });
   }
   let $headlineFlip = $('.headline-flip');
   console.log($headlineFlip);
   if (typeof $headlineFlip !== "undefined") {
      console.log('ja');
      const isViewportChecker = $.isFunction($.fn.viewportChecker);
      if (isViewportChecker) {
         $headlineFlip.viewportChecker({
            callbackFunction: function (elem, action) {
               let $hf = elem;
               if ($hf.hasClass("visible") || !isViewportChecker) {
                  let $items = $hf.find('.list-fliptext li');
                  const interval = $items.length * timeout + 1000;
                  animateFliptext($hf, $items);
                  setInterval(function () {
                     animateFliptext($hf, $items);
                  }, interval);
               }
            }
         });
      } else {
         $headlineFlip.each(function (index) {
            let $hf = $(this);
            if ($hf.hasClass("visible") || !isViewportChecker) {
               let $items = $hf.find('.list-fliptext li');
               const interval = $items.length * timeout + 1000;
               animateFliptext($hf, $items);
               setInterval(function () {
                  animateFliptext($hf, $items);
               }, interval);
            }
         });
      }
   }
});